<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="registrazione_da"
                label="Data registrazione da"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="registrazione_a"
                label="Data registrazione a"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="valuta_da"
                label="Data valuta da"
                v-model="filter.byEffectiveness.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="valuta_a"
                label="Data valuta a"
                v-model="filter.byEffectiveness.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="agencybook_type"
                label="Tipologia Movimento"
                :options="movimenti"
                v-model="filter.byAttribute.agencybook_type"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="payment_references"
                label="Causali"
                :options="opt_payment_reference"
                v-model="filter.byPaymentReference.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="treasuries"
                label="Comparto"
                :options="opt_sectors"
                v-model="filter.bySector.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="salesmen"
                label="Produttori"
                :options="opt_salesmen"
                v-model="filter.byBroker.id"
                :multiple="true"
                :taggable="salesmen_taggable"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="customers"
                label="Tipologia Anagrafica"
                :options="opt_customers"
                v-model="filter.attribute_type"
                @select="onSelectSuppliers"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="suppliers"
                label="Soggetto"
                :options="opt_suppliers"
                v-model="filter.byAgencyContact.id"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXPOAGBO',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <agency-books-table
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: [
          'bySector',
          'byBroker',
          'byPaymentReference',
          'byAgencyContact',
        ],
      }"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy', 'infomodal']"
    ></agency-books-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import AgencyBooksTable from "@/components/tables/AgencyBooks";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import ExportOptions from "@/components/ExportOptions";
// import client from "@/repositories/api";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  props: {
    contactId: Number,
  },
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterAgencyBook",
      resource: "agency_books",
      repository: "agency_book",
      tableRef: "AgencyBookTableRef",
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "agency_book"),
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "book_date",
        },
        {
          key: "effective_date",
          label: this.getDictionary("effective_date", "agency_book"),
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "effective_date",
        },
        {
          key: "agencybook_type.text",
          label: this.getDictionary("agencybook_type", "agency_book"),
          sortable: false,
        },
        {
          key: "payment_reference.code",
          label: this.getDictionary("payment_reference_id", "agency_book"),
          sortable: true,
          sortKey: "byPaymentReference.code",
        },
        {
          key: "additional_description",
          label: this.getDictionary("additional_description", "agency_book"),
          sortable: true,
        },
        {
          key: "sector_info",
          label: this.getDictionary("title", "sector"),
          sortable: true,
          sortKey: "bySector.title",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "agency_book"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
          sortable: true,
        },
        {
          key: "broker_info",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "agency_contacts_type",
          label: this.getDictionary("Tipo Cliente"),
          sortable: false,
        },
        {
          key: "agency_contacts_SURN",
          label: this.getDictionary("attribute_CLI_value", "agency_contact"),
          sortable: true,
          sortKey: "byAgencyContact.title",
        },
        {
          key: "agency_contacts_CNAM",
          label: this.getDictionary("attribute_SUP_value", "agency_contact"),
          sortable: true,
          sortKey: "byAgencyContact.title",
        },
        {
          key: "invoice_protocol_number",
          label: this.getDictionary("invoice_protocol_number", "agency_book"),
          sortable: true,
        },
      ],
      movimenti: [
        { value: "0", text: "Versamento" },
        { value: "1", text: "Prelievo" },
        { value: "3", text: "Giroconto" },
      ],
      opt_salesmen: [],
      salesmen_taggable: true,
      opt_customers: [
        { text: "Cliente", value: "Y" },
        { text: "Fornitore", value: "N" },
      ],
      opt_suppliers: [],
      opt_sectors: [],
      opt_payment_reference: [],
    };
  },
  components: {
    BaseSelect,
    BaseDatepicker,
    AgencyBooksTable,
    ExportOptions,
    // ButtonGroupTable,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byAttribute: {
          agencybook_type: null,
          gross: null,
          salesman_id: null,
          cod_causale: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byEffectiveness: {
          from: null,
          to: null,
        },
        bySector: {
          id: null,
        },
        byBroker: {
          id: null,
        },
        byPaymentReference: {
          id: null,
        },
        byAgencyContact: {
          id: null,
          SUP: null,
          CLI: null,
        },
        attribute_type: null,
      };
      return init;
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id, item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}`, item: item },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Movimento Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSelectSuppliers() {
      const Repo = RepositoryFactory.get("agency_contact");
      let qs = null;
      if (this.filter.attribute_type == "Y") {
        qs = "byAttribute[CLI]=Y";
        this.filter.byAgencyContact.CLI = "Y";
        this.filter.byAgencyContact.SUP = null;
      } else {
        qs = "byAttribute[SUP]=Y";
        this.filter.byAgencyContact.CLI = null;
        this.filter.byAgencyContact.SUP = "Y";
      }
      Repo.index(qs)
        .then((response) => {
          this.opt_suppliers = response.data.data.map((supplier) => {
            return { value: supplier.id, text: supplier.formatted_title };
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getPaymentReferences: "payment_references",
      getSectors: "sectors",
    }),
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("payment_references"))
      resources.push(
        this.$store
          .dispatch(`${path}payment_references`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.opt_payment_reference = this.getPaymentReferences();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      // this.getReports();
      this.opt_payment_reference = this.getPaymentReferences();
      this.isLoading = false;
    }
    /* */
  },
  mounted() {
    this.opt_salesmen = this.getSalesmen();
    this.opt_sectors = this.getSectors().filter(
      (sector) => sector.is_agency === "Y"
    );
    // this.opt_payment_reference = this.getPaymentReferences();
  },
};
</script>
